<template>
  <div class="p-10 pr-16">
    <newAssureur
      v-if="activeAssureur"
      :activation="activeAssureur"
      :type="type"
      @oga="retourAssureur"
    />
    <div
      class="flex items-center"
    >
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Assureurs ({{ total }})
        </div>
      </div>

      <div class="w-1/3">
        <div class="flex justify-end">
          <div class="w-4/6">
            <bouton
              :label="buttonLabel"
              radius="5.4px"
              weight="600"
              size="17px"
              @oga="retourClick"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <global-view
        v-if="!reloadSession"
        @label="retourEtape"
        @total="retourCount"
      />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/assureur/globalData'
import newAssureur from '../../component/popup/newAssureur'


export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    newAssureur
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter une compagnie',
      activeAdd: false,
      activeAssureur: false,
      reloadSession: false,
      total: 0
    }
  },

  created () {
  },

  methods: {
    retourCount (answer) {
      this.total = answer
    },

    retourClick () {
      this.activeAssureur = true
    },

    retourBack (answer) {
      this.activeAdd = answer
    },

    retourEtape (answer) {
      this.etape = answer
    },

    retourAssureur (answer) {
      if (answer === true){
        this.reloadSession = answer
        setTimeout(() => {
          this.reloadSession = false
        }, 200)

      }
      this.activeAssureur = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
