<template>
  <div class="rounded-10 h-auto bg-white w-full p-8">
    <div class="mt-12">
      <metier @total="retourCount"/>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import metier from './assureurTemplate'

export default {
  name: "Index",
  components: {
    metier
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      totalVerre: 0
    }
  },
  methods: {
    retourCount (answer) {
      this.$emit('total', answer)
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
</style>
