<template>
  <div class="">
    <div class="w-full flex text-c18 font-c4 text-left items-center item">
      <div class="w-3/6 flex items-center">
        <icon
          :data="icons.entreprise"
          height="50"
          width="50"
          original
        />
        <div class="ml-6">
          <div class="text-c18 font-c5">
            {{ item.name }}
          </div>

          <div class="text-c14 mt-2">
            <span class="text-60">{{ item.location }}</span>
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-2 text-left text-c12 text-60">
        <div>{{ item.phone }}</div>
        <div v-if="item.meta.secondPhoneNumber !== undefined">
          <div v-if="item.meta.secondPhoneNumber.length > 0">
            <div
              v-for="(i, index) in item.meta.secondPhoneNumber"
              :key="index"
            >
              {{ i }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-1/4 ml-6 text-left flex text-60 text-c14 items-center">
        <icon
          :data="icons.email"
          height="20"
          width="20"
          original
        />
        <div class="ml-3">
          {{ item.email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise,
        email
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
