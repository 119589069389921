<template>
  <div>
    <div class="flex items-center">
      <phoneNumber
        class="mt-2 w-4/5"
        @info="retourTelephone"
      />
      <div class="w-1/5 ml-6" />
    </div>
  </div>
</template>

<script>
import phoneNumber from '../../helper/form/phoneNumber'

export default {
  name: 'Success',
  components: { phoneNumber },

  props: {
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
    }
  },


  methods: {
    retourTelephone (answer) {
      let obj = {
        index: this.index,
        phoneNumber: answer
      }
      this.$emit('info', obj)
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../../assets/styles/sass/mixin";
</style>
