<template>
  <div class="all">
    <div class="w-full flex text-B text-c18 font-c4 text-left mb-10">
      <div class="w-3/6">
        COMPAGNIE
      </div>
      <div class="w-1/5 ml-2">
        TÉLÉPHONE
      </div>
      <div class="w-1/5 ml-6">
        EMAIL
      </div>
    </div>

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div
      v-for="(item, index) in allAssurance"
      :key="index"
      class="mt-4"
    >
      <div
        v-if="index > 0"
        class="item mt-4"
      />
      <liste
        class="mt-4"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import liste from './assureur'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },
  data () {
    return {
      icons: {
        option
      },
      allAssurance: [],
      charge: true
    }
  },
  created () {
    this.allFournisseur()
  },

  methods: {
    allFournisseur () {
      http.get(apiroutes.baseURL + apiroutes.allAssurance)
          .then(response => {
            this.allAssurance = response.reverse()
            this.$emit('total', response.length)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
}
.all{
  min-height: 400px;
}
</style>
